import React, { useEffect, useState } from 'react'
import PageLayout from '../Components/PageLayout/PageLayout'
import TenantList from '../Components/Tenants/TenantList'
import callApiWithToken from '../Utilities/ApiService/ApiService'
import TenantDetails from '../Components/Tenants/TenantDetails'
import { SubscriptionWithTenants, Tenant } from '../Interfaces/Types'
import TenantExtendedDetails from '../Components/Tenants/TenantExtendedDetails'

interface BreadcrumbsData {
  trigger: boolean
  data: {
    subscriptionId?: string
  }
}

const HomePage: React.FC = () => {
  const [tenants, setTenants] = useState<SubscriptionWithTenants[]>([])
  const [isTenantsLoading, setIsTenantsLoading] = useState(true)

  const [selectedTenant, setSelectedTenant] = useState<Tenant>()
  const [tenantUpdated, setTenantUpdated] = useState<Date>()

  const [breadcrumbsData, setBreadcrumbsData] = useState<BreadcrumbsData>({
    trigger: false,
    data: {}
  })

  useEffect(() => {
    callApiWithToken({
      endpoint: '/api/subscription',
      method: 'GET' // Your request payload
    })
      .then((data) => {
        setTenants(data)
        setIsTenantsLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [tenantUpdated])

  const handleTenantSelect = (tenant: Tenant) => {
    setSelectedTenant(tenant)
    setBreadcrumbsData({
      ...breadcrumbsData,
      data: { subscriptionId: tenant.subscriptionId },
      trigger: true
    })
  }

  const handleSubscriptionSelect = () => {
    setSelectedTenant(undefined)
    setBreadcrumbsData({
      ...breadcrumbsData,
      data: {},
      trigger: true
    })
  }

  return (
    <PageLayout
      section1Content={
        <TenantList
          breadcrumbsData={breadcrumbsData}
          onTenantUpdated={() => {
            setTenantUpdated(new Date())
            setIsTenantsLoading(true)
          }}
          onUpdated={(data) => {
            tenants.push({ subscription: data, tenants: [] })
          }}
          onSelected={(id) => handleTenantSelect(id)}
          isLoading={isTenantsLoading}
          propData={tenants}
        />
      }
      section2Content={
        <TenantDetails
          onUpdate={() => {
            setTenantUpdated(new Date())
            setIsTenantsLoading(true)
          }}
          onSubscriptionSelected={() => {
            handleSubscriptionSelect()
          }}
          onSelected={(id) => handleTenantSelect(id)}
          tenant={selectedTenant}
          propData={tenants}
        />
      }
      section3Content={<TenantExtendedDetails tenant={selectedTenant} />}
    />
  )
}

export default HomePage
