import React, { useEffect, useMemo, useState } from 'react'
import Text from '@embracesbs/component-text'
import { Audit, Tenant, FeatureFlags, MaintenanceType, NewMaintenance, DateRange } from '../../Interfaces/Types'
import callApiWithToken from '../../Utilities/ApiService/ApiService'
import DatePicker from '@embracesbs/component-datepicker'
import Container from '@embracesbs/component-container/Container'
import TextField from '@embracesbs/component-textfield/TextField'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import Button2 from '@embracesbs/component-button2/Button2'
import { BoldText, ContentText, InputField, TextContainer, TextContainerHeading } from '../StyledComponents/Components'

const style = {
  maxHeight: '80%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto' // Enables scrolling
}

interface TenantDetailsProps {
  tenant?: Tenant
}

const Maintenance: React.FC<TenantDetailsProps> = ({ tenant }) => {
  const [featureFlags, setFeatureFlags] = useState<Audit[]>([])
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState<boolean>(false)
  const [maintenance, setMaintenance] = useState<MaintenanceType>({ hasMaintenance: false })
  const [value, setValue] = useState<DateRange | undefined>({
    from: new Date(),
    to: new Date()
  })
  const [newMaintenance, setNewMaintenance] = useState<NewMaintenance>({
    startTime: '12:00',
    endTime: '12:00',
    title: JSON.stringify({ en: '', nl: '' }, null, 2),
    description: JSON.stringify({ en: '', nl: '' }, null, 2)
  })

  const deleteMaintenance = () => {
    callApiWithToken({
      endpoint: `/api/tenant/${tenant?.id}/tenantsconfigurationruntime/maintenance`,
      method: 'DELETE'
    })
      .then(() => {
        fetchMaintenance()
      })
      .catch((error) => {
        alert(error)
        console.error('Error removing maintenance:', error)
      })
  }

  useEffect(() => {
    fetchMaintenance()
  }, [tenant])

  const fetchMaintenance = async () => {
    callApiWithToken({
      endpoint: `/api/tenant/${tenant?.id}/tenantsconfigurationruntime/maintenance`,
      method: 'GET'
    })
      .then((response) => {
        setMaintenance(response)
      })
      .catch((error) => {
        alert(error)
        console.error('Error fetching maintenance:', error)
      })
  }

  function formatDateString(date: Date | undefined) {
    if (date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}T`
    }
    return ''
  }

  const handleSubmit = () => {
    const newStartingDate = formatDateString(value?.from) + newMaintenance.startTime
    const newEndDate = formatDateString(value?.to) + newMaintenance.endTime

    const maintenancePayload = {
      ...newMaintenance,
      startTime: newStartingDate,
      endTime: newEndDate,
      title: JSON.parse(newMaintenance.title),
      description: JSON.parse(newMaintenance.description)
    }

    callApiWithToken({
      endpoint: `/api/tenant/${tenant?.id}/tenantsconfigurationruntime/maintenance`,
      method: 'POST',
      body: maintenancePayload
    })
      .then(() => {
        fetchMaintenance()
      })
      .catch((error) => {
        alert(error)
        console.error('Error adding maintenance:', error)
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setNewMaintenance((prev) => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    callApiWithToken({
      endpoint: `/api/audit/${tenant?.id}`,
      method: 'GET'
    })
      .then((data) => {
        setFeatureFlags(data)
        setFeatureFlagsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [tenant])

  if (!tenant) {
    return (
      <Text align='center' textColor='grey'>
        Please select a tenant
      </Text>
    )
  }

  return (
    <div style={style}>
      {maintenance?.hasMaintenance ? (
        <TextContainer>
          <TextContainerHeading>Maintenance</TextContainerHeading>
          <BoldText>
            Title en: <ContentText>{maintenance?.title?.en}</ContentText>
          </BoldText>
          <BoldText>
            Title en: <ContentText>{maintenance?.title?.en}</ContentText>
          </BoldText>
          <BoldText>
            Description en: <ContentText>{maintenance?.description?.en}</ContentText>
          </BoldText>
          <BoldText>
            Title nl: <ContentText>{maintenance?.title?.nl}</ContentText>
          </BoldText>
          <BoldText>
            Description nl: <ContentText>{maintenance?.description?.nl}</ContentText>
          </BoldText>
          <BoldText>
            Start: <ContentText>{maintenance.startTime ? new Date(maintenance.startTime).toLocaleString() : ''}</ContentText>
          </BoldText>
          <BoldText>
            End: <ContentText> {maintenance.endTime ? new Date(maintenance.endTime).toLocaleString() : ''}</ContentText>
          </BoldText>
          <BoldText>
            bypassUrl:{' '}
            <ContentText>
              {' '}
              <a href={`https://${maintenance.url}?maintanancekey=${maintenance.key}`} target='_blank'>
                {maintenance.url}
              </a>
            </ContentText>
          </BoldText>
          <Button2
            buttonRadius='8px'
            buttonSize='40px'
            text={'Delete Maintenance'}
            isDanger={true}
            onClick={deleteMaintenance}
            variant='contained'
          />
        </TextContainer>
      ) : (
        <div>
          <Container>
            <TextFieldWrapper labelText={'Date range'}>
              <InputField readOnly placeholder='Select date range' value={`${value?.from.toDateString()}-${value?.to.toDateString()}`} />
            </TextFieldWrapper>
            <DatePicker
              isRange
              selectedDayFrom={value?.from}
              selectedDayTo={value?.to}
              onSelectingDays={({ selectedDayFrom, selectedDayTo }) => {
                const startOfFromDay = new Date(selectedDayFrom)
                startOfFromDay.setHours(0, 0, 0)
                const endOfToDay = new Date(selectedDayTo)
                endOfToDay.setHours(23, 59, 59)
                setValue({
                  from: startOfFromDay,
                  to: endOfToDay
                })
              }}
              isRightButtonDisabled={!value}
            />
          </Container>
          <Container>
            <TextFieldWrapper labelText={'StartTime'}>
              <TextField name='startTime' value={newMaintenance.startTime} onChange={handleChange} type='text' variant='default' />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'EndTime'}>
              <TextField name='endTime' value={newMaintenance.endTime} onChange={handleChange} type='text' variant='default' />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Title'}>
              <TextField name='title' value={newMaintenance.title} onChange={handleChange} type='text' variant='default' />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Description'}>
              <TextField name='description' value={newMaintenance.description} onChange={handleChange} type='text' variant='default' />
            </TextFieldWrapper>
          </Container>
          <Container>
            <Button2 buttonRadius='8px' buttonSize='40px' text={'Add Maintenance'} onClick={handleSubmit} variant='contained' />
          </Container>
        </div>
      )}
    </div>
  )
}

export default Maintenance
