import React, { useMemo, useState } from 'react'
import Text from '@embracesbs/component-text'
import TabGroup from '@embracesbs/component-tabgroup/TabGroup'
import { Tenant, SubscriptionWithTenants, TabId } from '../../Interfaces/Types'
import TernantDeploymentSettings from './TernantDeploymentSettings'
import TenantMonitoring from './TenantMonitoring'
import FeatureFlag from './FeatureFlag'
import AuditLog from './AuditLog'
import Maintenance from './Maintenance'
import Notifications from './Notifications'
import Settings from './Settings'
import Breadcrumbs from './Breadcrumbs'
interface tenantDetailsProbs {
  tenant?: Tenant
  onUpdate: () => void
  propData: SubscriptionWithTenants[]
  onSelected: (Tenant: Tenant) => void
  onSubscriptionSelected: (Tenant: Tenant) => void
}

const TenantDetails: React.FC<tenantDetailsProbs> = ({ tenant, onUpdate, propData, onSelected, onSubscriptionSelected }) => {
  const [selectedTab, setSelectedTab] = useState(1)
  const handleClick = (id: number) => {
    setSelectedTab(id)
  }
  const subscription = useMemo(() => {
    if (tenant) {
      return propData.find((subscriptionWithTenants) => {
        return subscriptionWithTenants?.tenants?.some((sub) => {
          return sub.id === tenant?.id
        })
      })
    }
  }, [propData, tenant])

  const renderDetailsContent = useMemo(() => {
    switch (selectedTab) {
      case TabId.DeploytimeSetting:
        return <TernantDeploymentSettings onUpdate={() => onUpdate} tenant={tenant} />
      case TabId.FeatureFlags:
        return <FeatureFlag tenant={tenant} />
      case TabId.Maintenance:
        return <Maintenance tenant={tenant} />
      case TabId.Notifications:
        return <Notifications tenant={tenant} />
      case TabId.Monitoring:
        return <TenantMonitoring tenant={tenant} />
      case TabId.AuditLog:
        return <AuditLog tenant={tenant} />
      case TabId.Settings:
        return <Settings tenant={tenant} />
      default:
        return <div>Not yet implemented</div>
    }
  }, [selectedTab, tenant])

  const tabs = [
    {
      id: TabId.DeploytimeSetting,
      text: 'Deploytime Setting'
    },
    {
      id: TabId.FeatureFlags,
      text: 'Feature Flags'
    },
    {
      id: TabId.Maintenance,
      text: 'Maintenance'
    },
    {
      id: TabId.Notifications,
      text: 'Notifications'
    },
    {
      id: TabId.Monitoring,
      text: 'Monitoring'
    },
    {
      id: TabId.AuditLog,
      text: 'Audit Log'
    },
    {
      id: TabId.Settings,
      text: 'Default settings'
    }
  ]

  if (!tenant) {
    return (
      <>
        <Text align='center' textColor='grey'>
          Please select a tenant
        </Text>
      </>
    )
  }

  if (tenant.deployStatus == 'initializing') {
    return (
      <>
        <Text align='center' textColor='grey'>
          Tenant {tenant.tenantName} is initializing to view the progress press PipelineUrl link{' '}
        </Text>
      </>
    )
  }

  return (
    <>
      <Text textStyle='h6'>{tenant.tenantDisplayname}</Text>
      <Breadcrumbs
        tenant={tenant}
        onSubscriptionClick={() => onSubscriptionSelected(tenant)}
        onTenantClick={() => onSelected(tenant)}
        subscription={subscription}
      />

      <br></br>
      <TabGroup onTabClick={handleClick} tabs={tabs} activeKey={1} />
      <br></br>
      <br></br>
      {renderDetailsContent}
    </>
  )
}

export default TenantDetails
