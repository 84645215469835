import React, { useState, useEffect, ChangeEvent } from 'react'
import Text from '@embracesbs/component-text'
import { Audit, Tenant, Notification, NotificationsState, PRIORITY_INFO, PRIORITY_NORMAL, PRIORITY_HIGH } from '../../Interfaces/Types'
import callApiWithToken from '../../Utilities/ApiService/ApiService'
import DatePicker from '@embracesbs/component-datepicker'
import Container from '@embracesbs/component-container/Container'
import TextField from '@embracesbs/component-textfield/TextField'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import Button2 from '@embracesbs/component-button2/Button2'
import Autocomplete from '@embracesbs/component-autocomplete/Autocomplete'
import { BoldText, ContentText, InputField, TextContainer, TextContainerHeading, TextHeading } from '../StyledComponents/Components'

interface TenantDetailsProps {
  tenant?: Tenant
}

const style = {
  maxHeight: '80%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto' // Enables scrolling
}

const Notifications: React.FC<TenantDetailsProps> = ({ tenant }) => {
  const [featureFlags, setFeatureFlags] = useState([])
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [value, setValue] = useState({
    from: new Date(),
    to: new Date()
  })
  const [newNotification, setNewNotification] = useState({
    severity: 1,
    starttime: '12:00',
    endtime: '12:00',
    content: JSON.stringify(
      {
        en: {
          main: 'main content',
          title: 'title content'
        },
        nl: {
          main: 'main nl content',
          title: 'main nl title'
        }
      },
      null,
      2
    )
  })

  useEffect(() => {
    fetchNotifications()
    fetchFeatureFlags()
  }, [tenant])

  const fetchNotifications = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/tenant/${tenant?.id}/tenantsconfigurationruntime/notifications`,
        method: 'GET'
      })
      if (response ?? response.length > 0) {
        setNotifications(response)
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  const fetchFeatureFlags = async () => {
    if (tenant) {
      try {
        const data = await callApiWithToken({
          endpoint: `/api/audit/${tenant.id}`,
          method: 'GET'
        })
        setFeatureFlags(data)
        setFeatureFlagsLoaded(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const formatDateString = (dateString: Date | undefined) => {
    if (dateString) {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}T`

      return formattedDate
    }
  }

  const handleSubmit = () => {
    const newStartingDate = formatDateString(value?.from) + newNotification.starttime
    const newEndDate = formatDateString(value?.to) + newNotification.endtime

    const notification = {
      ...newNotification,
      starttime: newStartingDate,
      endtime: newEndDate,
      content: JSON.parse(newNotification.content)
    }

    const updatedArray = [...notifications, notification]

    updateNotifications(updatedArray)
  }

  const updateNotifications = async (updatedArray: Notification[]) => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/tenant/${tenant?.id}/tenantsconfigurationruntime/notifications`,
        method: 'POST',
        body: updatedArray
      })
      if (response ?? response.length > 0) {
        setNotifications(response)
        setNewNotification({
          severity: 1,
          starttime: '12:00',
          endtime: '12:00',
          content: JSON.stringify(
            {
              en: {
                main: 'main content',
                title: 'title content'
              },
              nl: {
                main: 'main nl content',
                title: 'main nl title'
              }
            },
            null,
            2
          )
        })
      }
    } catch (error) {
      alert(error)
      console.log(error)
    }
  }
  function removeQuotes(str: string | undefined | null): string {
    if (!str) return '' // Handle cases where str is null or undefined
    return str.replace(/^"|"$/g, '')
  }

  const deleteNotification = (index: number) => {
    const updatedNotifications = notifications.filter((_, i) => i !== index)
    updateNotifications(updatedNotifications)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setNewNotification((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSeverityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewNotification((prev) => ({
      ...prev,
      severity: +event.target.value
    }))
  }

  const priorityEnum = [
    { label: 'Info', value: PRIORITY_INFO },
    { label: 'Normal', value: PRIORITY_NORMAL },
    { label: 'High', value: PRIORITY_HIGH }
  ]

  return (
    <div style={style}>
      <Autocomplete value={newNotification.severity} name='' onChange={(e) => handleSeverityChange(e)} options={priorityEnum} />
      <Container>
        <TextFieldWrapper labelText={'Date range'}>
          <InputField readOnly placeholder='Select date range' value={`${value?.from.toDateString()} - ${value?.to.toDateString()}`} />
        </TextFieldWrapper>
        <DatePicker
          isRange
          selectedDayFrom={value?.from}
          selectedDayTo={value?.to}
          onSelectingDays={({ selectedDayFrom, selectedDayTo }) => {
            const startOfFromDay = new Date(selectedDayFrom)
            startOfFromDay.setHours(0, 0, 0)
            const endOfToDay = new Date(selectedDayTo)
            endOfToDay.setHours(23, 59, 59)
            setValue({
              from: startOfFromDay,
              to: endOfToDay
            })
          }}
          isRightButtonDisabled={!value}
        />
      </Container>
      <Container>
        <TextFieldWrapper labelText={'Start Time'}>
          <TextField value={newNotification.starttime} onChange={handleChange} name='starttime' type='text' variant='default' />
        </TextFieldWrapper>
        <TextFieldWrapper labelText={'End Time'}>
          <TextField value={newNotification.endtime} onChange={handleChange} name='endtime' type='text' variant='default' />
        </TextFieldWrapper>
      </Container>
      <Container>
        <TextFieldWrapper labelText={'Content'}>
          <TextField value={newNotification.content} onChange={handleChange} name='content' type='text' variant='default' />
        </TextFieldWrapper>
      </Container>
      <Container>
        <Button2 buttonRadius='8px' buttonSize='40px' text={'Add Notification'} onClick={handleSubmit} variant='contained' />
      </Container>
      {notifications && notifications.length > 0 && <TextHeading>Notifications:</TextHeading>}
      {notifications && notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <TextContainer key={index} className='mb-2'>
            <BoldText>
              Title en: <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.title, null, 2))}</ContentText>
            </BoldText>
            <BoldText>
              Content en: <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.main, null, 2))}</ContentText>
            </BoldText>
            <BoldText>
              Title nl: <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.title, null, 2))}</ContentText>
            </BoldText>
            <BoldText>
              Content nl: <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.main, null, 2))}</ContentText>
            </BoldText>
            <BoldText>
              Start:<ContentText>{new Date(notification?.starttime).toLocaleString()} </ContentText>
            </BoldText>
            <BoldText>
              End: <ContentText>{new Date(notification?.endtime).toLocaleString()}</ContentText>
            </BoldText>
            <BoldText>
              Severity: <ContentText>{notification?.severity}</ContentText>
            </BoldText>
            <Button2
              buttonRadius='8px'
              buttonSize='40px'
              text={'Delete Notification'}
              isDanger={true}
              onClick={() => {
                deleteNotification(index)
              }}
              variant='contained'
            />
          </TextContainer>
        ))
      ) : (
        <>
          <TextHeading>No notifications available.</TextHeading>
        </>
      )}
    </div>
  )
}

export default Notifications
