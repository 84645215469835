import React, { useEffect } from 'react'
import Text from '@embracesbs/component-text'
import { Audit, Tenant } from '../../Interfaces/Types'
import { FeatureFlags } from '../../Interfaces/Types'
import callApiWithToken from '../../Utilities/ApiService/ApiService'
interface tenantDetailsProbs {
  tenant?: Tenant
}

const style = {
  maxHeight: '50%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto' // Enables scrolling
}

const Settings: React.FC<tenantDetailsProbs> = ({ tenant }) => {
  const [featureFlags, setFeatureFlags] = React.useState<Audit[]>([])
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = React.useState<Boolean>(false)

  useEffect(() => {
    callApiWithToken({
      endpoint: `/api/audit/${tenant?.id}`,
      method: 'GET' // Your request payload
    })
      .then((data) => {
        setFeatureFlags(data)
        setFeatureFlagsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [tenant])

  if (!tenant) {
    return (
      <>
        <Text align='center' textColor='grey'>
          Please select a tenant
        </Text>
      </>
    )
  }

  return <div style={style}>In development csp settings to be added</div>
}

export default Settings
