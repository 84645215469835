import { Configuration } from '@azure/msal-browser'

export const msalConfig: Configuration = {
  auth: {
    clientId: '200b679b-75f1-4bb5-8f9e-ca2b37d6f852',
    authority: 'https://login.microsoftonline.com/c9870c14-4129-4d46-aad2-3f691939642c',
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
}

// Scopes for the login request
export const loginRequest = {
  scopes: ['api://tenants-backend-i9YaPN/.default'] // Example scope for Microsoft Graph, adjust based on your needs
}
