import React, { useEffect } from 'react'
import Text from '@embracesbs/component-text'
import { Tenant } from '../../Interfaces/Types'
import Switch from '@embracesbs/component-switch'
import Table from '@embracesbs/component-table'
import TableRow from '@embracesbs/component-tablerow'
import { FeatureFlags } from '../../Interfaces/Types'
import callApiWithToken from '../../Utilities/ApiService/ApiService'
import { sortByLabel } from '../../Utilities/helpers/helpers'

interface tenantDetailsProbs {
  tenant?: Tenant
}

const FeatureFlag: React.FC<tenantDetailsProbs> = ({ tenant }) => {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlags[]>([])
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = React.useState<Boolean>(false)

  const toggleFeatureFlag = (name: string) => {
    setFeatureFlagsLoaded(true)
    const updatedFlags = featureFlags.map((flag) => {
      if (flag.name === name) {
        return { ...flag, enabled: !flag.enabled }
      }
      return flag
    })

    callApiWithToken({
      endpoint: `/api/appconfig/${tenant?.id}/featureflags`,
      method: 'PUT',
      body: updatedFlags
    })
      .then((data) => {
        setFeatureFlags(data.sort(sortByLabel))
        setFeatureFlagsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    callApiWithToken({
      endpoint: `/api/appconfig/${tenant?.id}/featureflags`,
      method: 'GET' // Your request payload
    })
      .then((data) => {
        setFeatureFlags(data.sort(sortByLabel))
        setFeatureFlagsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [tenant])

  if (!tenant) {
    return (
      <>
        <Text align='center' textColor='grey'>
          Please select a tenant
        </Text>
      </>
    )
  }

  return (
    <>
      <Table>
        {featureFlags.map((item, index) => (
          <TableRow key={index}>
            <Switch
              name={item.name}
              label={item.name}
              value='a'
              isChecked={item.enabled}
              onClick={() => {
                toggleFeatureFlag(item.name)
              }}
            />
            <Text>{item.label}</Text>
          </TableRow>
        ))}
      </Table>
    </>
  )
}

export default FeatureFlag
